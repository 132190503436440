import logo from "../../img/LogoIcon-FC.webp";
import logoLetters from "../../img/LogoLetters-FC-2-600-nav-menu.webp";
import gradWave from "../../img/graduate-waving-nav.webp"
import vets from "../../img/vet-center-2.webp"
import car from "../../img/car-show-shelby.webp"
import mockDrop from "../../img/mockDrop-program-selector.webp"
import studentRes from "../../img/student-resources-forNav.webp"
import success from "../../img/success-stories-nav.webp"

class Header extends HTMLElement {
    connectedCallback() {
      this.innerHTML = `           
        <!--nav-bar and menu section-->
        <section class="nav-bar">
          <div class="btn-group1 flex-row">
            <a data-barba-prevent="self" class="no-dec" href="./index.html">
              <img
                src="${logo}"
                alt="Hodges University, stay near go far"
              />
            </a>
            <a
              class="nav-btn btn-primary"
              target="_blank"
              rel="noreferrer noopener"
              href="https://adfs.hodges.edu/adfs/ls?wa=wsignin1.0&wtrealm=urn%3amyhugo.hodges.edu%3a443&wctx=https%3a%2f%2fmyhugo.hodges.edu%2f_layouts%2f15%2fAuthenticate.aspx%3fSource%3d%252F&wreply=https%3a%2f%2fmyhugo.hodges.edu%2f_trust%2fdefault.aspx"
              >MyHUgo Login</a
            >
          </div>
          <div class="btn-group2 flex-row">
          
         
          
          </div>
          <div class="btn-group3">
            <div class="site-search-bar flex-row-between">
              <form action="/search.html" method="get" class="flex-row-between">
                <input
                  title="Enter search here for Hodges.edu"
                  name="q"
                  placeholder="Search Hodges.edu..."
                  type="text"
                />
                <button type="submit" class="site-search-bar__search-btn flex-row">
                  <i
                    id="site-search-button"
                    class="fa-solid fa-magnifying-glass"
                  ></i>
                </button>
              </form>
            </div>
            <p id="menu-label">Menu</p>
            <div class="menu-btn">
              <div class="menu-btn__line"></div>
              <div class="menu-btn__line"></div>
              <div class="menu-btn__line"></div>
            </div>
          </div>
        </section>
        <!--nav-bar menu-->
        <div class="nav-bar__menu">
          <div class="flex-row mobile-nav-btns">
            <div class="mobile-site-search-bar flex-row-between">
              <form action="/search.html" method="get" class="flex-row-between">
                <input
                  title="Enter search here for Hodges.edu"
                  name="q"
                  placeholder="Search Hodges.edu..."
                  type="text"
                />
                <button type="submit" class="site-search-bar__search-btn flex-row">
                  <i
                    id="site-search-button"
                    class="fa-solid fa-magnifying-glass"
                  ></i>
                </button>
              </form>
            </div>
        
        
         
          </div>
          <div class="flex-row-between height-stretch">
            <nav>
             <a
                class="link"
                data-barba-prevent="self"
                class="spacer-for-line slide-in"
                href="./index.html"
                >home</a
              >

              <p class="nav-links" href="#">Current Students</p>
              <span class="link-underline">s</span>

              <p class="nav-links" href="#"></p>
              <span class="link-underline">s</span>

              <p class="nav-links" href="#"></p>
              <span class="link-underline">s</span>

              <p class="nav-links" href="#">Programs</p>
              <span class="link-underline">s</span>

              <a
                class="link"
                data-barba-prevent="self"
                class="spacer-for-line slide-in"
                href="./events.html"
                >
              </a>

              <a
                class="link"
                data-barba-prevent="self"
                class="spacer-for-line slide-in"
                href="./dental-hygiene-clinic.html"
                >
              </a>

              <p class="nav-links" href="#">About Hodges University</p>
              <span class="link-underline">s</span>
              <a
                class="link"
                data-barba-prevent="self"
                class="spacer-for-line slide-in"
                href="./give-and-engage.html"
                ></a
              >

              <a
                class="link"
                data-barba-prevent="self"
                class="spacer-for-line slide-in"
                target="_blank"
                rel="noopener noreferrer"
                href="https://hodges.wearthefund.com/hodges_university/shop/home/"
                ></a
              >

            </nav>
            <div class="flex-column height-stretch">
              <img
                loading="lazy"
                class="nav-bar__menu-img"
                src="${logoLetters}"
                alt="Hodges University, stay near go far"
              />
            </div>
          </div>
          <div class="square1"></div>
          <div class="square2"></div>
          <div class="square3"></div>
          <div class="square4"></div>
          <div class="nav-link__menu">
            <h2>Current Students</h2>
            <div class="nav-link__menu-backbtn">
              <i class="fas fa-chevron-left"></i>
              <p>back</p>
            </div>
            <div class="grid-3-col spacer-top">
              <div class="flex-column">
                <h4 class="nav-section-header">Financial Info</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./financial-aid.html"
                  >Financial Aid
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./scholarships.html"
                  >Scholarships
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./tuition-and-fees.html"
                  >Tuition & Fees
                </a>
              </div>
              <div class="flex-column">
                <h4 class="nav-section-header"></h4>
                
                
                
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://studserv.hodges.edu/Student/Planning/DegreePlans/"
                  >Register for Classes
                </a>
              </div>
              <div class="flex-column">
              <h4 class="nav-section-header">Student Success</h4>
                <!-- <a
                  data-barba-prevent="self"
                  class="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://success.hodges.edu/"
                  >Success Hub
                </a> -->
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./library.html"
                  >Library
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./student-experience.html"
                  >Student Experience
                </a>
              </div>
            </div>
            <div class="grid-2-col spacer-top">
              <div class="flex-column">
                <h4 class="nav-section-header">Course Info</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./academic-calendar.html"
                  >Academic Calendar
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://hucatalog.hodges.edu/sites/catalog/Pages/Master-Course-Schedule.aspx"
                  >Course List
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./registrar.html"
                  >Registrar
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./university-catalogs.html"
                  >University Catalog
                </a>
              </div>
              <div class="flex-column">
                <h4 class="nav-section-header">Additional Resources</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./veteran-students.html"
                  >Veteran Students
                </a>
                <!-- <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./graduation.html"
                  >Graduation/Commencement
                </a> -->
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="directory"
                  href="https://hucatalog.hodges.edu/directory/Lists/Hodges%20Directory/By%20Name.aspx"
                  >Faculty & Staff Directory
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./student-handbooks.html"
                  >Student Handbooks
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://s3.amazonaws.com/hodges.edu/Registrar/terms-and-conditions/Registration_Terms_and_Conditions_v5043023.pdf"
                  >Registration Terms & Conditions
                </a>
              </div>
            </div>
          </div>
          <div class="nav-link__menu">
            <h2>Military students</h2>
            <div class="nav-link__menu-backbtn">
              <i class="fas fa-chevron-left"></i>
              <p>back</p>
            </div>
            <div class="grid-2-row">
            <a
                class="width-95"
                data-barba-prevent="self"
                href="./veteran-students.html"
              >
                <div class="spotlight">
                  <img
                    loading="lazy"
                    src="${vets}"
                    alt="One-Stop-Shop for all resources for Veteran Students at Hodges University."
                  />
                  <div class="text-container">
                    <h3>Veteran Student Resources</h3>
                    <p>Your One-Stop-Shop for all Veteran Resources at Hodges</p>
                  </div>
                </div>
              </a>
              <a
                class="width-95"
                data-barba-prevent="self"
                href="./military-veteran-friendly.html"
              >
                <div class="spotlight">
                  <img
                    loading="lazy"
                    src="${gradWave}"
                    alt="Hodges veteran student walking happily at graduation"
                  />
                  <div class="text-container">
                    <h3>Military and Veteran Friendly</h3>
                    <p>
                      Hodges University is a top rated school for veterans 3 years in a row.
                      See why here!
                    </p>
                  </div>
                </div>
              </a>
              <a
                class="width-95"
                data-barba-prevent="self"
                href="./hodgesu-car-show.html"
              >
                <div class="spotlight">
                  <img
                    loading="lazy"
                    src="${car}"
                    alt="Hodges veteran student walking happily at graduation"
                  />
                  <div class="text-container">
                    <h3>"Putting it in Gear" Car Show</h3>
                    <p>
                      The Annual Hodges Car Show, benefiting the Dr. Peter Thomas 
                      Veterans Services Center
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="nav-link__menu">
            <h2>Future Students</h2>
            <div class="nav-link__menu-backbtn">
              <i class="fas fa-chevron-left"></i>
              <p>back</p>
            </div>
            <div class="grid-2-col spacer-top">
              <div class="flex-column">
                <h4 class="nav-section-header">Financial Info</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./financial-aid.html"
                  >Financial Aid
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./scholarships.html"
                  >Scholarships
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./tuition-and-fees.html"
                  >Tuition & Fees
                </a>
              </div>
              <div class="flex-column">
                <h4 class="nav-section-header">Courses</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./registrar.html"
                  >Registrar
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://hucatalog.hodges.edu/sites/catalog/Pages/Master-Course-Schedule.aspx"
                  >Course List
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./programindex.html"
                  >Programs
                </a>
              </div>
            </div>
            <div class="grid-2-col spacer-top">
              <div class="flex-column">
                <h4 class="nav-section-header">Enrollment</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./apply-splash.html"
                  >Apply Now
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./request-info.html"
                  >Request Info
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./admissions-process.html"
                  >Admissions Process
                </a>
              </div>
              <div class="flex-column">
                <h4 class="nav-section-header">Additional Resources</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./international-students.html"
                  >International Students
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./veteran-students.html"
                  >Veteran Students
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youvisit.com/tour/61594?pl=v&from=embed-js-failed"
                  >Virtual Tour
                </a>
              </div>
            </div>
          </div>
          <div class="nav-link__menu">
            <h2>Degree Levels</h2>
            <div class="nav-link__menu-backbtn">
              <i class="fas fa-chevron-left"></i>
              <p>back</p>
            </div>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./prog-masters-degrees.html"
                  >Master's Degrees
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./prog-esl.html"
                  >ESL
                </a>
              </div>
              
            <div class="spacer-top"></div>
          </div>
          <div class="nav-link__menu">
            <h2>About Hodges</h2>
            <div class="nav-link__menu-backbtn">
              <i class="fas fa-chevron-left"></i>
              <p>back</p>
            </div>
            <div class="grid-2-col spacer-top">
              <div class="flex-column">
                <h4 class="nav-section-header">About Us</h4>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./about-our-staff.html"
                  >About Our Faculty & Staff
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./presidents-welcome.html"
                  >President's Welcome
                </a>
               
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./university-catalogs.html"
                  >University Catalog
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./academic-table.html"
                  >Academic Table
                </a>
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./program-delivery-guide.html"
                  >Program Delivery Guide
                </a>
              </div>
              <div class="flex-column">
                <h4 class="nav-section-header">Our Community</h4>
                <!-- <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./diversity.html"
                  >Diversity
                </a> -->
              
               
                <a
                  data-barba-prevent="self"
                  class="nav-link"
                  href="./talon-catalog.html"
                  >Talon Magazine
                </a>
              
              </div>
            </div>  
            <h4 class="nav-section-header spacer-top">Additional Resources</h4>
            <div class="grid-3-col">
              <a
                data-barba-prevent="self"
                class="nav-link"
                href="./consumer-info.html"
                >Consumer Info
              </a>
              <a
                data-barba-prevent="self"
                class="nav-link"
                href="./accreditation.html"
                >Accreditation
              </a>
              <a
                data-barba-prevent="self"
                class="nav-link"
                href="./student-achievement.html"
                >Student Achievement
              </a>
              <a
                data-barba-prevent="self"
                class="nav-link"
                href="./ferpa-information.html"
                >FERPA Info
              </a>
              <a
                data-barba-prevent="self"
                class="nav-link"
                href="./institutional-effectiveness.html"
                >Institutional Effectiveness
              </a>
              <a
                data-barba-prevent="self"
                class="nav-link"
                href="./sara.html"
                >SARA
              </a>
              <a
                data-barba-prevent="self"
                class="nav-link"
                href="./privacy-policy.html"
                >Privacy Policy
              </a>
            </div>
          </div>
        </div>
        <!--nav-bar and menu section-->      
      `;
    }
  }

  customElements.define('main-header', Header);
