//sass goes here
import "../scss/info-pages.scss";

//imported logic goes here

//Global elements
import "./js-reusable/header"
import "./js-reusable/gdpr"
import "./js-reusable/footer"


import navBarFunc from "./js-comps/navbar";
import collapsibleFunc from "./js-comps/collapsible";
import translatorFunc from "./js-comps/translator";
import gdprFunctionality from "./js-comps/gdpr";
import switcherFunc from "./js-comps/switcher";

//YouTube Facade
import '@justinribeiro/lite-youtube';

navBarFunc();
collapsibleFunc();
translatorFunc();
gdprFunctionality();
switcherFunc();
//custom logic goes here
