const switcherFunc = () => {
  // Grab switcher elements
  const switchers = document.querySelectorAll(".switcher");
  const switcherButtons = document.querySelectorAll(
    ".switcher__button"
  );

  // Add on click event for each element
  switcherButtons.forEach((switcherButton, i) => {
    switcherButton.addEventListener("click", () => {
      // remove class from expanded switcher
      switchers.forEach((switcher) => {
        switcher.classList.remove("open");
      });

      // Add class to expanded switcher
      switchers[i].classList.add("open");
    });
  });
};

// exports function to be used on other scripts
export default switcherFunc;
