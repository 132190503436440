import amazon from "../../img/amazonsmile.webp"

class Footer extends HTMLElement {
    connectedCallback() {
      this.innerHTML = `
            <footer>
                <div class="featured-links flex-column">
                
                </div>
                <div class="line"></div>
                <div class="footer-bottom grid-3-col">
                <div class="footer-text-container">
                    <h3>Hodges University</h3>
                    <p>FORT MYERS CAMPUS</p>
                    <p>4501 Colonial Blvd Fort Myers,</p>
                    <p>Florida 33966</p>
                    <p>1-239-938-7700</p>
                    <p>Toll Free: 800-466-8017</p>
                </div>
                <div class="flex-column">
                    <div class="translator">
                    <h3>Translate</h3>
                    <h4>Select a Language</h4>
                    <div class="flex-row">
                        <a
                        href="#"
                        onclick="doGTranslate('en|en');return false;"
                        title="English"
                        class="no-dec"
                        style="background-position: -0px -0px"
                        >English</a
                        ><a
                        href="#"
                        onclick="doGTranslate('en|es');return false;"
                        title="Spanish"
                        class="no-dec"
                        style="background-position: -600px -200px"
                        >Español</a
                        >
                    </div>
                    <select onchange="doGTranslate(this);">
                        <option value="">Select Language</option>
                        <option value="en|af">Afrikaans</option>
                        <option value="en|sq">Albanian</option>
                        <option value="en|ar">Arabic</option>
                        <option value="en|hy">Armenian</option>
                        <option value="en|az">Azerbaijani</option>
                        <option value="en|eu">Basque</option>
                        <option value="en|be">Belarusian</option>
                        <option value="en|bg">Bulgarian</option>
                        <option value="en|ca">Catalan</option>
                        <option value="en|zh-CN">Chinese (Simplified)</option>
                        <option value="en|zh-TW">Chinese (Traditional)</option>
                        <option value="en|hr">Croatian</option>
                        <option value="en|cs">Czech</option>
                        <option value="en|da">Danish</option>
                        <option value="en|nl">Dutch</option>
                        <option value="en|en">English</option>
                        <option value="en|et">Estonian</option>
                        <option value="en|tl">Filipino</option>
                        <option value="en|fi">Finnish</option>
                        <option value="en|fr">French</option>
                        <option value="en|gl">Galician</option>
                        <option value="en|ka">Georgian</option>
                        <option value="en|de">German</option>
                        <option value="en|el">Greek</option>
                        <option value="en|ht">Haitian Creole</option>
                        <option value="en|iw">Hebrew</option>
                        <option value="en|hi">Hindi</option>
                        <option value="en|hu">Hungarian</option>
                        <option value="en|is">Icelandic</option>
                        <option value="en|id">Indonesian</option>
                        <option value="en|ga">Irish</option>
                        <option value="en|it">Italian</option>
                        <option value="en|ja">Japanese</option>
                        <option value="en|ko">Korean</option>
                        <option value="en|lv">Latvian</option>
                        <option value="en|lt">Lithuanian</option>
                        <option value="en|mk">Macedonian</option>
                        <option value="en|ms">Malay</option>
                        <option value="en|mt">Maltese</option>
                        <option value="en|no">Norwegian</option>
                        <option value="en|fa">Persian</option>
                        <option value="en|pl">Polish</option>
                        <option value="en|pt">Portuguese</option>
                        <option value="en|ro">Romanian</option>
                        <option value="en|ru">Russian</option>
                        <option value="en|sr">Serbian</option>
                        <option value="en|sk">Slovak</option>
                        <option value="en|sl">Slovenian</option>
                        <option value="en|es">Spanish</option>
                        <option value="en|sw">Swahili</option>
                        <option value="en|sv">Swedish</option>
                        <option value="en|th">Thai</option>
                        <option value="en|tr">Turkish</option>
                        <option value="en|uk">Ukrainian</option>
                        <option value="en|ur">Urdu</option>
                        <option value="en|vi">Vietnamese</option>
                        <option value="en|cy">Welsh</option>
                        <option value="en|yi">Yiddish</option>
                    </select>
                    </div>
                </div>
                <div class="footer-text-container flex-column align-center">
                <div class="flex-row social-links">
                <a
                    aria-label="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="no-dec"
                    href="https://www.facebook.com/HodgesUniversity/"
                >
                    <div class="social-link">
                    <i class="fab fa-facebook-f"></i>
                    </div>
                </a>
                <a
                    aria-label="twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="no-dec"
                    href="https://twitter.com/hodgesu"
                >
                    <div class="social-link">
                    <i class="fab fa-twitter"></i>
                    </div>
                </a>
                <a
                    aria-label="linked in"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="no-dec"
                    href="https://www.linkedin.com/school/hodges-university/"
                >
                    <div class="social-link">
                    <i class="fab fa-linkedin-in"></i>
                    </div>
                </a>
                <a
                    aria-label="instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="no-dec"
                    href="https://www.instagram.com/hodgeshawks/"
                >
                    <div class="social-link">
                    <i class="fab fa-instagram"></i>
                    </div>
                </a>
                <a
                    aria-label="youtube"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="no-dec"
                    href="https://www.youtube.com/c/HodgesUniversity90/"
                >
                    <div class="social-link">
                    <i class="fab fa-youtube"></i>
                    </div>
                </a>
                </div>
                </div>
                </div>
            </footer>
            <div class="copyright">
                <p>
                © Copyright Hodges University 2018-2023 | Privacy Policy | Consumer
                Information | Hodges University is regionally accredited by the Southern
                Association of Colleges and Schools Commission on Colleges to award
                associate, bachelor’s, and master’s degrees. Questions about the
                accreditation of Hodges University may be directed in writing to the
                Southern Association of Colleges and Schools Commission on Colleges at
                1866 Southern Lane, Decatur, GA 30033-4097, by calling (404) 679-4500,
                or by using information available on
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.sacscoc.org"
                    >SACSCOC’s website</a
                >. All other inquiries regarding Hodges University’s educational
                programs, admissions requirements, financial aid, etc., should be
                directed to Hodges University and not to the Southern Association of
                Colleges and Schools Commission on Colleges. The curriculum of each
                clinical program at Hodges University meets the professional licensure
                requirements of the state of Florida. These clinical programs include
                the Master of Science in Clinical Mental Health Counseling, Bachelor of
                Science in Nursing, Bachelor of Science in Dental Hygiene, Associate of
                Science in Physical Therapist Assistant, Certificate in Emergency
                Medical Technician, Certificate in Paramedics, and Certificate in
                Practical Nursing. Hodges University has not made a determination
                whether the programs, listed above, meet the professional licensure
                requirements of other states. Students who plan to relocate outside of
                Florida must confirm that the curriculum meets that state’s
                requirements.
                </p>
            </div>
        `;
    }
  }

  customElements.define('main-footer', Footer);
