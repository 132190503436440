class GDPR extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="gdpr flex-column">
      <div class="flex-row">
          <h3 class="sub-heading">GDPR Notice</h3>
          <button class="btn__small-text btn-secondary" id="accept-gdpr">
          Accept
          </button>
      </div>
      <p class="text-center">
          We use cookies and other data collection tools to create the best
          possible user experience, to better understand how they are used please
          visit our privacy policy page. By continuing to use this site you are
          giving us your consent to do this.To the extent the General Data
          Protection Regulation (“GDPR”) is applicable to me, by visiting this
          website I hereby consent to the processing of my Personal Data as
          defined by the GDPR for the purposes outlined and provided for in
          Hodges’ policies, as amended from time to time. I understand that in
          certain circumstances, I have the right to object to the processing of
          my Personal Data. I further understand that I have the right to request
          (1) access to my Personal Data; (2) rectification of mistakes or errors
          and/or erasure of my Personal Data; (3) that Hodges restrict processing
          of my Personal Data; and (4) that Hodges provide my Personal Data upon
          request in a portable format
      </p>
      </div>
    `;
  }
}

customElements.define('gdpr-message', GDPR);